import { navigate } from "gatsby-link";
import React, { useState, useEffect } from "react";
import shapeBoxes from "../../images/shape_boxes.png";
import {
  CarDetails,
  CustomerDetails,
  SpareParts,
  constructYears,
  nigeriaStates,
} from "./utils";

const FormWizard = ({ title }) => {
  const [stage, setStage] = useState(1);
  const [systems, setSystems] = useState(null);
  const [allYears, setAllYears] = useState([]);
  const [allCars, setAllCars] = useState([]);
  const [carMakes, setCarMakes] = useState([]);
  const [states, setStates] = useState(null);
  const [carSystems, setCarSystems] = useState("");
  const [systemParts, setSystemParts] = useState("");
  const [carMake, setCarMake] = useState("");
  const [carModel, setCarModel] = useState("");
  const [carYear, setCarYear] = useState("");
  const [selectedSystem, setSelectedSystem] = useState("");
  const [selectedSystemPart, setSelectedSystemPart] = useState("");
  const [quantity, setQuantity] = useState(1);
  const [custName, setCustName] = useState("");
  const [custPhone, setCustPhone] = useState("");
  const [custEmail, setCustEmail] = useState("");
  const [custState, setCustState] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [orders, setOrders] = useState([]);
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    const tempYears = constructYears();
    setAllYears(tempYears);
    async function fetchStates() {
      const result = await nigeriaStates();
      setStates(result);
    }
    fetchStates();
    fetch(`${process.env.GATSBY_BASE_URL}vehicles`)
      .then((json) => json.json())
      .then((data) => {
        const sysm = data.map((item) => {
          return { value: item, label: item };
        });
        setAllCars(sysm);
      })
      .catch((err) => {
        console.log("cars error", err);
      });
    fetch(`${process.env.GATSBY_BASE_URL}car-system`)
      .then((json) => json.json())
      .then((data) => {
        setSystems(data);
        const sysm = Object.keys(data).map((item) => {
          return { value: item, label: item };
        });
        setCarSystems(sysm);
      })
      .catch((err) => {
        console.log("systems error", err);
      });
  }, []);

  const handleCarMake = (selected) => {
    setCarMake(selected);
    fetch(`${process.env.GATSBY_BASE_URL}vehicles/${selected.value}`)
      .then((json) => json.json())
      .then((data) => {
        const sysm = data.map((item) => {
          return { value: item, label: item };
        });
        setCarMakes(sysm);
      });
  };

  const handleCarModel = (selected) => {
    setCarModel(selected);
  };

  const handleSelectedYear = (selected) => {
    setCarYear(selected);
  };

  const handleSystemChange = (selected) => {
    setSelectedSystem(selected);
    const tempResult = Object.keys(systems).filter(
      (index) => index === selected.value
    );
    const result = systems[tempResult].map((item) => {
      return { value: item, label: item };
    });
    setSystemParts(result);
    setSelectedSystemPart("");
  };

  const handlePartChange = (selected) => {
    setSelectedSystemPart(selected);
  };

  const handleInputChange = (data, type) => {
    if (type === "name") {
      setCustName(data);
    } else if (type === "email") {
      setCustEmail(data);
    } else if (type === "phone") {
      setCustPhone(data);
    }
  };

  const handleFormAddition = (nextPage) => {
    if (selectedSystem) {
      const data = {
        car_part: selectedSystemPart.value,
        car_system: selectedSystem.value,
        quantity: Number(quantity),
      };
      setOrders((prevOrders) => [...prevOrders, data]);
      setSelectedSystem(null);
      setSelectedSystemPart(null);
      setQuantity(1);
      setSystemParts(null);
      if (nextPage) {
        setStage(3);
      }
    }
  };

  const submitForm = () => {
    setIsLoading(true);
    const postData = {
      sparePartsQuote: {
        name: custName,
        email: custEmail,
        mobile: custPhone,
        car_make: carMake.value,
        car_model: carModel.value,
        car_year: carYear.value,
        location: custState.value,
        parts_order: orders,
      },
    };
    fetch(`${process.env.GATSBY_BASE_URL}spare-parts-quote`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(postData),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.quoteRef) {
          registerUser();
          navigate("/confirmation/", {
            state: { ref: data.quoteRef },
            replace: true,
          });
        } else {
          setHasError(true);
        }
      })
      .catch((error) => {
        console.error("Form submit Error:", error);
        setHasError(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const registerUser = () => {
    const payload = {
      first_name: custName.split(" ")[0],
      last_name: custName.split(" ")[1],
      email: custEmail,
      phone_number: custPhone,
      channel: "Spare parts",
    };
    fetch(`${process.env.GATSBY_API_URL}auth/register`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    })
      .then((response) => response.text())
      .then((data) => console.log("silent register", data))
      .catch((error) => console.log("silent register: Error:", error));
  };

  return (
    <>
      <div className="form-section">
        <img src={shapeBoxes} alt="shapes" className="shapes" />
        <div className="container">
          <h2>{title}</h2>
          <div className="form-steps">
            <div className={`form-step ${stage === 1 ? "active" : null}`}>
              <h3>1</h3>
              <span>
                Car <br /> details
              </span>
            </div>
            <div className={`form-step ${stage === 2 ? "active" : null}`}>
              <h3>2</h3>
              <span>
                Spare part <br />
                details
              </span>
            </div>
            <div className={`form-step ${stage === 3 ? "active" : null}`}>
              <h3>3</h3>
              <span>
                Customer <br />
                details
              </span>
            </div>
          </div>
        </div>
        <div className="container form-wrapper">
          <div className="form-level">
            <div className="form-level-bg"></div>
            <p>{stage}</p>
          </div>
          {stage === 1 && (
            <CarDetails
              cars={allCars}
              carMakes={carMakes}
              carMake={carMake}
              carModel={carModel}
              carYear={carYear}
              years={allYears}
              handleCarMake={handleCarMake}
              handleYear={handleSelectedYear}
              handleCarModel={handleCarModel}
              onSubmit={() => setStage(2)}
            />
          )}
          {stage === 2 && (
            <SpareParts
              systems={carSystems}
              systemChange={handleSystemChange}
              systemValue={selectedSystem}
              systemParts={systemParts}
              changeSystemParts={handlePartChange}
              systemPart={selectedSystemPart}
              quantityChange={({ target }) => setQuantity(target.value)}
              quantity={quantity}
              addFormElement={handleFormAddition}
              onSubmit={() => handleFormAddition(true)}
            />
          )}
          {stage === 3 && (
            <CustomerDetails
              name={custName}
              email={custEmail}
              phone={custPhone}
              state={custState}
              states={states}
              handleInputChange={handleInputChange}
              changeState={(selected) => setCustState(selected)}
              onSubmit={submitForm}
              isLoading={isLoading}
              hasError={hasError}
            />
          )}
        </div>
      </div>
      {/* <div className="trust form">
        <div className="container center">
          {formType === "sparePart" ? (
            <p>
              Our Spare parts quote gives car owners peace of mind as it comes
              with warranty, genuineness with a seamless experience. Because
              Carviva is a platform that offers the best services not only to
              car owners but also to autocare service providers across Nigeria.
              Let’s make sustainable decisions together!
            </p>
          ) : (
            <p>
              Our Ticketing system enables car owners log issues affecting their
              cars and our personnels will work to resolve them immediately
              through sales of genuine parts or by recommending expert mechanics
              to attend to your them.
            </p>
          )}
        </div>
      </div> */}
    </>
  );
};

export default FormWizard;
