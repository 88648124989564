import React from "react";
import Faq from "../faq";
import FormWizard from "../form";
import { ActionBanner } from "./utils";

const faqData = [
  {
    id: 1,
    title: "Why should I be a Carviva Autocare customer?",
    text:
      "Carviva is a platform that connects car users and car owners to a wide network of modern OEM workshops, garages and autocare service providers across Nigeria.",
  },
  {
    id: 2,
    title: "How flexible is Carviva Autocare pricing?",
    text:
      "With Carviva, at the tap of a button on a smart phone, you can buy fuel, buy engine oil, buy airtime/Data while service appointments such as car wash, car diagnostics, lube change, wheels balancing, alignment e.t.c can be booked and paid for on-the-go and from the comfort of homes and offices.",
  },
  {
    id: 3,
    title: "How do car owners pay for service?",
    text:
      "Car owners pay cash on service delivery at service center or via prepaid card payments before visiting service center.",
  },
];

const AutoMidSection = () => {
  const url = typeof window !== "undefined" ? window.location.href : "";
  return (
    <>
      <div className="home-midsection">
        <ActionBanner page={url.includes("quotes") ? "quotes" : null} />
        <FormWizard title="Please Fill Quote Form Below" />
      </div>
      <div className="trust form">
        <div className="container center">
          <p>
            Spare Parts are critical to your mobility and safety needs. At
            Carviva, join many car owners who are enjoying peace of mind because
            our Spare Parts are genuine, affordable and we offer warranty on
            repairs. Let's save you the stress of searching for parts or buying
            fakes; we bring Spare Parts to your doorstep!
          </p>
          {/* <p>We bring Spare Parts to your doorstep!</p> */}
        </div>
      </div>
      <Faq questions={faqData} title="Top questions from customers" />
    </>
  );
};

export default AutoMidSection;
