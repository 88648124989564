import React from "react";
import Modal from "react-modal";
import { Link } from "gatsby";
import "../components/style.scss";
import Helmet from "../components/layout/helmet";
import AutoHeader from "../components/headers/auto";
import AutoMidSection from "../components/contents/auto";
import Footer from "../components/layout/footer";
import SparePartModal from "../images/modal_spareparts.jpg";
// Modal.setAppElement("#yourAppElement");

const AutoPage = () => {
  const [showModal, setShowModal] = React.useState(false);

  React.useEffect(() => {
    setTimeout(() => setShowModal(true), 500);
  }, []);

  return (
    <>
      <Modal
        isOpen={showModal}
        onRequestClose={() => setShowModal(false)}
        className="modal_content"
        overlayClassName="modal_overlay"
        contentLabel="Example Modal"
      >
        <button className="close" onClick={() => setShowModal(false)}>
          &#x2715;
        </button>
        <button onClick={() => setShowModal(false)}>
          <img src={SparePartModal} alt="modal" />
        </button>
      </Modal>
      <div className="homepage quotes">
        <Helmet />
        <AutoHeader />
        <AutoMidSection />
        <Footer />
      </div>
    </>
  );
};

export default AutoPage;
